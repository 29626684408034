import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import AdobeAnalyticsBanner from "../components/Platforms/AdobeAnalyticsBanner/AdobeAnalyticsBanner";
import AdobeAnalyticsProductTour from "../components/Platforms/AdobeAnalyticsProductTour/AdobeAnalyticsProductTour";
import AdobeAnalyticsServices from "../components/Platforms/AdobeAnalyticsServices/AdobeAnalyticsServices";
import AdobeAnalyticsBenefits from "../components/Platforms/AdobeAnalyticsBenefits/AdobeAnalyticsBenefits";
import { Helmet } from "react-helmet";
function AdobeAnalytics() {
	const scrollTop = () => {
		window.scroll(0, 1);
	};
	return (
		<Layout>
		<Helmet>
		<meta charSet="utf-8" />
        <title>
		Hashout Technologies - Adobe Analytics
        </title>
        <meta name="description" content="See beyond vanity metrics to find insights that drive the bottom line." ></meta></Helmet>
            <SolutionsHelmet />
			<AdobeAnalyticsBanner />
			<AdobeAnalyticsServices />
			<AdobeAnalyticsProductTour />
			<AdobeAnalyticsBenefits />
			<LearnAboutUs page="adobe-analytics"/>
			<a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
				<img
					className="go-to-top__img"
					src="../../solutions/go-to-top.png"
					alt="Go to top"
				/>
			</a>
		</Layout>
	);
}
export default AdobeAnalytics;
