export const data = [
  {
    projectName: "Citrix Solution Wizard",
    solutionArea: "Marketing",
    tags: ["UX", "AEM", "Vue.js", "Node.js", "AWS", "CI/CD"],
    headerImg: "../../../ourwork/Citrix Solution Wizard.png",
    img: "../../../ourwork/citrix.png",
  },
  {
    projectName: "Citrix + Azure ROI Calculator",
    solutionArea: "Marketing",
    tags: ["UX", "AEM", "Vue.js", "Node.js", "AWS", "CI/CD"],
    headerImg: "../../../ourwork/Citrix Solution Wizard.png",
    img: "../../../ourwork/citrix.png",
  },
  {
    projectName: "Citrix Product Documentation",
    solutionArea: "Product Documentation",
    tags: ["UX", "Netlify", "Jenkins", "Jamstack", "CI/CD", "UI"],
    headerImg: "../../../ourwork/Citrix Product Documentation.png",
    img: "../../../ourwork/citrix.png",
  },

  {
    projectName: "Citrix Developer Community",
    solutionArea: "Online Communities",
    tags: ["UX", "AWS", "Vue.js", "CI/CD", "Discourse"],
    headerImg: "../../../ourwork/Citrix Developer Community.png",
    img: "../../../ourwork/citrix.png",
  },
  {
    projectName: "Geotab Support Communities",
    solutionArea: "Online Communities",
    tags: ["UX", "Salesforce Communities"],
    headerImg: "../../../ourwork/Geotab Support Communities.png",
    img: "../../../ourwork/Geotab.png",
  },
  {
    projectName: "Geotab Case Management",
    solutionArea: "Support",
    tags: ["UX", "Salesforce Communities"],
    headerImg: "../../../ourwork/Geotab Case Management.png",
    img: "../../../ourwork/Geotab.png",
  },
  {
    projectName: "Geotab Installer finder",
    solutionArea: "Support, Product Development",
    tags: ["UX", "Next.js", "GCP", "CI/CD"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Geotab Product Documentation",
    solutionArea: "Product Documentation",
    tags: ["UX", "Next.js", "GCP", "CI/CD", "Google Docs", "Salesforce"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "SSO",
    solutionArea: "Product Documentation, Support",
    tags: ["UX", "Salesforce Communities"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Straumann Website-Nuvo Free case",
    solutionArea: "Marketing",
    tags: ["UX", "AEM", "Vue.js", "Node.js", "AWS", "CI/CD"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Straumann Website-Neodent Quality",
    solutionArea: "Marketing",
    tags: ["UX", "AEM"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Straumann Website-Live Digital",
    solutionArea: "Marketing",
    tags: ["UX", "AEM"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Teeth Today",
    solutionArea: "Marketing",
    tags: ["UX", "Jamstack", "React", "Azure", "ContentFul"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "NUVO ROI Calculator",
    solutionArea: "Marketing",
    tags: ["UX", "React", "AEM", "Azure"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Neodent Smile Decider",
    solutionArea: "Marketing",
    tags: ["UX", "AEM"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Straumann APEX - Virtual Tradeshow",
    solutionArea: "Virtual Tradeshows",
    tags: ["UX", "three.js", "UNITY", "Azure", "CI/CD"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Straumann APEX - Virtual Tradeshow App",
    solutionArea: "Virtual Tradeshows",
    tags: ["UX", "UNITY", "Azure", "CI/CD"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Citrix SolutionWizard",
    solutionArea: "Marketing",
    tags: ["UX", "AEM", "Vue.js", "Node.js", "AWS", "CI/CD"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Medentika Digital Catalog",
    solutionArea: "Mobile Solutions, Marketing",
    tags: ["UX", "React Native", "Contentful"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Weavr.ai",
    solutionArea: "Product Development",
    tags: ["UX", "React", "Gatsby", "CI/CD"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "StoryTrails",
    solutionArea: "Mobile Solutions",
    tags: ["UX", "React Native", "CI/CD"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Citrix SolutionWizard",
    solutionArea: "Marketing",
    tags: ["UX", "AEM", "Vue.js", "Node.js", "AWS", "CI/CD"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "OLLMOO",
    solutionArea: "Marketing, Product Development",
    tags: ["UX", "React", "CI/CD", "Azure", "CriteriaCorp", "GetID", "Auth0"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "ISB - Executive Education Portal",
    solutionArea: "Marketing",
    tags: ["UX", "AEM", "Adobe Campaigns"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Prada",
    solutionArea: "eCommerce",
    tags: ["UX", "AEM", "Algolia"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Chicoo",
    solutionArea: "eCommerce",
    tags: ["UX", "AEM", "SAP-Hybris", "SOLR"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Gewiss",
    solutionArea: "Marketing",
    tags: ["UX", "AEM"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Nutan",
    solutionArea: "Marketing",
    tags: ["UX", "AEM"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Foreside",
    solutionArea: "Marketing",
    tags: ["UX", "AEM"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Harbour Women’s Health",
    solutionArea: "Marketing",
    tags: ["UX", "React", "AWS"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Citrix Developer Communities",
    solutionArea: "Online Communities",
    tags: ["UX", "LWC", "Salesforce Experience Cloud"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "AspenTech Support Case creation flow",
    solutionArea: "Support",
    tags: ["UX", "LWC", "Salesforce Experience Cloud"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Elastic Search",
    solutionArea: "Search",
    tags: ["Elastic Search", "GCP"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Implmentation on Geotab Doc",
    solutionArea: "Marketing",
    tags: ["UX", "AEM"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  },
  {
    projectName: "Geotab Documentation Site",
    solutionArea: "Documentation Experience",
    tags: ["UX", "Next.js", "GCP"],
    headerImg: "../../../ourwork/Geotabcard.png",
    img: "../../../ourwork/Geotabcard1.png",
  }
];
