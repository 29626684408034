import React, { useEffect, useState } from "react";
import  "./OurWorkBanner.module.scss";


function OurWorkBanner() {
	return (
		<section className="section section--banner section--gray-bg">
			<div className="container">
				<div className="container__row">
					<div className="container__col-md-6 --section-large-tb-padding ">
						<h1 className="container__heading ourworkbanner_heading" >How Citrix created a simple, scalable Product Documentation experience and cut costs by 65% </h1>
                        <a className="card__action --action-link ourwork_readmore" href="/How-Citrix-created-a-simple-scalable-Product-Documentation-experience-with-Hashout.pdf" target="_blank">READ CASE STUDY</a>
						<p className="card_vector"><img src="../../ourwork/Vector6.png"></img><img src="../../ourwork/Vector6.png"></img></p>
                        <p className="ourwork_quote">Our previous vendors over-complicated projects and created tremendous overhead. Every day was just another set of emergencies that had to be dealt with</p>
                        <p className="ourwork_bold">Associate Director of Marketing and Communications
                        </p>
							<p>Leading Dentistry Company</p>
                    </div>
					<div className="container__col-md-6 --flex-center">
						<img
							className="container__img --bg-absolute-right ourworkbanner_img"
							src="../../solutions/solutions/citrixbanner.png"
							alt="Web Experiences"
						/>
					</div>
				</div>
			</div>
    
		</section>
	);
}

export default OurWorkBanner;