import React from "react";
// import "../styles/solutions/style.scss";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import JoinUsBanner from "../components/JoinUs/JoinUsBanner/JoinUsBanner";
import JoinUsBannerImg from "../components/JoinUs/JoinUsBannerImg/JoinUsBannerImg";
import JoinUsCareer from "../components/JoinUs/JoinUsCareer/JoinUsCareer";
import JoinUsBenefits from "../components/JoinUs/JoinUsBenefits/JoinUsBenefits";
import JoinUsOpenPositions from "../components/JoinUs/JoinUsOpenPositions/JoinUsOpenPositions";
import JoinUsDiscover from "../components/JoinUs/JoinUsDiscover/JoinUsDiscover";
import JoinUsOurHeros from "../components/JoinUs/JoinUsOurHeros/JoinUsOurHeros";
// import JoinUsFooter from "../components/JoinUs/JoinUsFooter/JoinUsFooter";
import { Helmet } from "react-helmet";
function JoinUs() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hashout Technologies - Join-Us</title>
        <meta
          name="description"
          content="
         We are a product engineering company focused on creating amazing digital experiences for enterprises worldwide.
         We’re growing rapidly and are always on the lookout for great talent."
        ></meta>
      </Helmet>
      <SolutionsHelmet />
      <JoinUsBanner />
      <JoinUsBannerImg />
      <JoinUsCareer />
      <JoinUsBenefits />
      <div id="view_all_opening">
        <JoinUsOpenPositions />
      </div>

      {/* <JoinUsDiscover /> */}
      <JoinUsOurHeros />
      {/* <JoinUsFooter /> */}

      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img go-to-top__margin"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default JoinUs;
