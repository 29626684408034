import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./ourworkcarousel.module.scss";
import Ourworkbanner from "./OurWorkBanner";

function OurProjects() {
  const carouselResponsive = {
    0: {
      items: 1,
      slideBy: 1,
    },
    576: {
      items: 1,
      slideBy: 1,
    },
    768: {
      items: 1,
      slideBy: 1,
    },
    992: {
      items: 1,
      slideBy: 1,
    },
    1200: {
      items: 1,
      slideBy: 1,
    },
  };
  // const modalCarouselResponsive = {
  //   0: {
  //     items: 1,
  //     slideBy: 1,
  //   },
  //   576: {
  //     items: 1,
  //     slideBy: 1,
  //   },
  //   768: {
  //     items: 1,
  //     slideBy: 1,
  //   },
  //   992: {
  //     items: 1,
  //     slideBy: 1,
  //   },
  //   1200: {
  //     items: 1,
  //     slideBy: 1,
  //   },
  // };

  function padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  }

  var loop = false;
  const counter = (event) => {
    var items = event.item.count;
    var slideBy = event.page.size;

    var indexCount = 2;
    if (slideBy == 1) {
      indexCount = 1;
    }

    if (loop == true) {
      var item = event.item.index - 2;
    } else {
      var item = event.item.index + indexCount;
    }

    // it loop is true then reset counter from 1
    if (item > items) {
      item = item - items;
    }

    item = Math.ceil(item / slideBy);
    items = Math.ceil(items / slideBy);

    $(".owl-dots").html(padDigits(item, 2) + " / " + padDigits(items, 2));
  };

  const counter_modal = (event) => {
    var items = event.item.count;
    var slideBy = event.relatedTarget.settings.slideBy;

    var indexCount = 1;
    if (slideBy == 1) {
      indexCount = 1;
    }

    if (loop == true) {
      var item = event.item.index - 2;
    } else {
      var item = event.item.index + indexCount;
    }

    // it loop is true then reset counter from 1
    if (item > items) {
      item = item - items;
    }

    item = Math.ceil(item / slideBy);
    items = Math.ceil(items / slideBy);

    $(".modal-wrapper .owl-dots").html(item + " / " + items);
  };
  return (
    <>
      
       
            
            {typeof window !== "undefined" && OwlCarousel && (
              <OwlCarousel
              className={`${styles["owl_theme"]} ${styles["our_projects_carousel"]}`}
              margin={30}
              slideBy={"page"}
              autoplay={true}
              autoplayTimeout={6000}
              autoplaySpeed={1000}
              autoplayHoverPause={true}
              // stagePadding={35}
              responsive={carouselResponsive}
              nav={true}
              dots={true}
              onInitialized={counter}
              onTranslated={counter}
             
              >
               <div><Ourworkbanner /></div>
               <div><Ourworkbanner /></div>
               <div><Ourworkbanner /></div>
               <div><Ourworkbanner /></div>
               <div><Ourworkbanner /></div>
              </OwlCarousel>
            )}
         
       
      
    </>
  );
}

export default OurProjects;

