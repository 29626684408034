const CONSTANTS = {
    BLOG_PATH: '/blog/',
    BLOG_CATEGORIES: {
        all: 'ALL',
        technology: 'Technology',
        business: 'Business',
        artificialIntelligence: 'Artificial Intelligence',
        sampleCategory: 'Sample Category'
    },
     BLOG_FEATURED: {
        
        title:'Part 3 - Getting started with Adobe Analytics - capturing data using Adobe Launch'
    },

    CONTACT_US_SUCCESS_MESSAGE: 'Thank You for Contacting Us',
    CONTACT_US_SUCCESS_MESSAGE_POSITION: 'bottom-center',
    CONTACT_US_SUCCESS_MESSAGE_AUTO_CLOSE_DURATION: 8000,
    CONTACT_US_FAILURE_MESSAGE: 'Failed to send contact us message',
    CONTACT_US_SUCCESS_MESSAGE_POSITION: 'bottom-center',
    TURBO_HIRE_CARRER_PAGE_JOBS_URL: 'https://api.turbohire.co/api/careerpagejobs',
    THEMES: {
        light: {
            code:'LIGHT',
            foreground: '#000000',
            background: '#ffffff',
            logo: 'Logo.svg',
            mobileMenu: 'menu.svg'
        },
        aem: {
            code: "AEM",
            foreground: '#ffffff',
            background: '#f36416',
            logo: 'Logo-light.svg',
            mobileMenu: 'menu-dark.svg'
        }
    }
    
}

export default CONSTANTS;