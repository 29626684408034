import React from "react";
import { useState } from "react";
import { data } from "./content";
import { Multiselect } from "multiselect-react-dropdown";
import Ourworkcard from "./OurWorkcards/ourworkcard";
import "./ourworkselect.module.scss";

const options = [
  "Marketing",
  "Product Documentation",
  "Online Communities",
  "Support",
  "Product Development",
  "Product Documentation",
  "Virtual Tradeshows",
  "Mobile Solutions",
  "eCommerce",
  "Search",
  "Documentation Experience",
];
const solutionArea = [
  { key: "Marketing", value: "Marketing" },
  { key: "Product Documentation", value: "Product Documentation" },
  { key: "Online Communities", value: "Online Communities" },
  { key: "Support", value: "Support" },
  { key: "Product Development", value: "Product Development" },
  { key: "Product Documentation", value: "Product Documentation" },
  { key: "Virtual Tradeshow", value: "Virtual Tradeshow" },
  { key: "Mobile Solutions", value: "Mobile Solutions" },
  { key: "eCommerce", value: "eCommerce" },
  { key: "Search", value: "Search" },
  { key: "Documentation Experience", value: "Documentation Experience" },
];
const tags = [
  "UX",
  "AEM",
  "Vue.js",
  "Node.js",
  "AWS",
  "CI/CD",
  "Netlify",
  "Jenkins",
  "Jamstack",
  "Discourse",
  "Salesforce Communities",
  "Next.js",
  "GCP",
  "Google Docs",
  "Salesforce",
  "React",
  "Azure",
  "ContentFul",
  "three.js",
  "UNITY",
  "React Native",
  "Gatsby",
  "CriteriaCorp",
  "GetID",
  "Auth0",
  "Adobe Campaigns",
  "Algolia",
  "SAP-Hybris",
  "SOLR",
  "LWC",
  "Salesforce Experience Cloud",
  "Elastic Search",
];
function OurWorkSelect() {
  const objectArray = [
    { key: "UX", value: "UX" },
    { key: "AEM", value: "AEM" },
    { key: "Vue.js", value: "Vue.js" },
    { key: "Node.js", value: "Node.js" },
    { key: "AWS", value: "AWS" },
    { key: "CI/CD", value: "CI/CD" },
    { key: "Netlify", value: "Netlify" },
    { key: "Jenkins", value: "Jenkins" },
    { key: "Jamstack", value: "Jamstack" },
    { key: "Discourse", value: "Discourse" },
    { key: "Salesforce Communities", value: "Salesforce Communities" },
    { key: "Next.js", value: "Next.js" },
    { key: "GCP", value: "GCP" },
    { key: "Google Docs", value: "Google Docs" },
    { key: "Salesforce", value: "Salesforce" },
    { key: "React", value: "React" },
    { key: "Azure", value: "Azure" },
    { key: "ContentFul", value: "ContentFul" },
    { key: "three.js", value: "three.js" },
    { key: "UNITY", value: "UNITY" },
    { key: "React Native", value: "React Native" },
    { key: "Gatsby", value: "Gatsby" },
    { key: "CriteriaCorp", value: "CriteriaCorp" },
    { key: "Auth0", value: "Auth0" },
    { key: "Adobe Campaigns", value: "Adobe Campaigns" },
    { key: "Algolia", value: "Algolia" },
    { key: "SAP-Hybris", value: "SAP-Hybris" },
    { key: "SOLR", value: "SOLR" },
    { key: "LWC", value: "LWC" },
    {
      key: "Salesforce Experience Cloud",
      value: "Salesforce Experience Cloud",
    },
    { key: "Elastic Search", value: "Elastic Search" },
  ];
  let filteredTagData = data;
  let isTagPresent = false;
  let filteredData = data;

  const [isSolutionVisible, setisSolutionVisible] = useState(true);
  const [isTagVisible, setIsTagVisible] = useState(false);
  const [isViewMoreClicked, setisViewMoreClicked] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isViewMoreTagsClicked, setisViewMoreTagsClicked] = useState(false);
  const [isVisibleTags, setIsVisibleTags] = useState(true);
  const [tagsValue, setTagsValue] = useState([]);
  const [solutionAreaSelected, setSolutionAreaSelected] = useState([]);

  const onSolutionAreaAdd = (event) => {
    setSolutionAreaSelected([...event]);
    setTagsValue();
    setIsTagVisible(false);
    setisSolutionVisible(true);
    console.log("solutioncard", event);
  };

  if (solutionAreaSelected.length > 0) {
    filteredData = data.filter((data) => {
      if (data.solutionArea === solutionAreaSelected[0].value) {
        return data;
      }
    });
  }

  const onTagsValueAdd = (event) => {
    setTagsValue([...event]);
    setSolutionAreaSelected([]);
    setisSolutionVisible(false);
    setIsTagVisible(true);
    //  console.log("mmm",event)
  };

  const onTagsValueRemove = (event) => {
    setTagsValue([...event]);
    //  console.log("removetag",event)
  };

  if (tagsValue?.length > 0) {
    [
      (filteredTagData = data.filter((data) => {
        data.tags.map((tag) => {
          if (
            tagsValue.map((tagValue) => {
              if (tagValue.value === tag) {
                isTagPresent = true;
              }
            })
          ) {
          }
        });
        if (isTagPresent) {
          isTagPresent = false;
          return data;
        }
      })),
    ];
  }

  // soolution area

  const viewMoreHandler = () => {
    setIsVisible(false);
    setisViewMoreClicked(true);
    setisViewMoreTagsClicked(false);
  };
  // tags

  const viewMoreHandlerTags = () => {
    setIsVisibleTags(false);
    setisViewMoreTagsClicked(true);
    setisViewMoreClicked(false);
  };

  return (
    <>
      <div className="ourwork_select">
        <div>
          {/* solution area */}
          <Multiselect
            options={solutionArea}
            displayValue="key"
            showCheckbox={true}
            onSelect={onSolutionAreaAdd}
            onRemove={onTagsValueRemove}
            className="tags"
            singleSelect
            selectedValues={solutionAreaSelected}
            customArrow={
              <>
                <img src="../../ourwork/down.png"></img>
              </>
            }
            placeholder="SOLUTION AREA"
            style={{
              chips: {},
              multiselectContainer: {},
              searchBox: {
                border: "none",
                "border-radius": "0px",
              },
              icon_down_dir: {},
            }}
          />
        </div>
        <div className="split"></div>

        <div className="row">
          <Multiselect
            options={objectArray}
            displayValue="key"
            showCheckbox={true}
            onSelect={onTagsValueAdd}
            onRemove={onTagsValueRemove}
            className="tags"
            showArrow
            selectedValues={tagsValue}
            hidePlaceholder
            customArrow={
              <>
                <img src="../../ourwork/down.png"></img>
              </>
            }
            placeholder="TAGS"
            style={{
              chips: {},
              optionContainer: {},
              optionListContainer: {
                width: "auto",
              },
              multiselectContainer: {},

              searchBox: {
                display: "flex",
                border: "none",
                "border-radius": "0px",
              },
            }}
          />
        </div>
      </div>
      {/* solution area */}

      {isSolutionVisible && isVisible && (
        <div>
          <div className="ourwork_projects">{filteredData.length} projects</div>
          <div className="ourwork_card">
            {filteredData.length < 6 ? (
              <Ourworkcard
                className="ourwork_card"
                filteredData={filteredData}
              />
            ) : (
              <>
                <Ourworkcard
                  className="ourwork_card"
                  id="all_cards"
                  filteredData={filteredData.slice(0, 6)}
                />
                <div className="button_align">
                  <button id="all" onClick={viewMoreHandler}>
                    View more
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {/* tags */}

      <div className="ourwork_card">
        {isSolutionVisible && isViewMoreClicked && (
          <Ourworkcard className="ourwork_card" filteredData={filteredData} />
        )}{" "}
      </div>

      {/* <div>{true?(<div>{filteredData.length}</div>):(<div>No</div>)}</div> */}

      {isTagVisible && isVisibleTags && tagsValue.length > 0 && (
        <div>
          <div className="ourwork_projects">
            {filteredTagData.length} projects
          </div>
          <div className="ourwork_card">
            {filteredTagData.length < 6 ? (
              <Ourworkcard
                className="ourwork_card"
                filteredData={filteredTagData}
              />
            ) : (
              <>
                <Ourworkcard
                  className="ourwork_card"
                  id="all_cards"
                  filteredData={filteredTagData.slice(0, 6)}
                />
                <div className="button_align">
                  <button
                   
                    id="all"
                    onClick={viewMoreHandlerTags}
                  >
                    View more
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div className="ourwork_card">
        {isTagVisible && isViewMoreTagsClicked && (
          <Ourworkcard
            className="ourwork_card"
            filteredData={filteredTagData}
          />
        )}
      </div>
      {/* <Ourworkcard filteredData={filteredData} />
        { && <Ourworkcard filteredData={filteredTagData} />} */}
    </>
  );
}

export default OurWorkSelect;
