import React from "react";
import CardStyles from "./ourworkcard.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "../../OurWork/ourworkcarousel.module.scss";

function Ourworkcard(props) {
  const modalCarouselResponsive = {
    0: {
      items: 1,
      slideBy: 1,
    },
    576: {
      items: 1,
      slideBy: 1,
    },
    768: {
      items: 2,
      slideBy: 1,
    },
    992: {
      items: 2,
      slideBy: 1,
    },
    1200: {
      items: 2,
      slideBy: 1,
    },
  };
  function padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  }
  var loop = false;
  const counter = (event) => {
    var items = event.item.count;
    var slideBy = event.page.size;

    var indexCount = 2;
    if (slideBy == 1) {
      indexCount = 1;
    }
    if (loop == true) {
        var item = event.item.index - 2;
      } else {
        var item = event.item.index + indexCount;
      }
      if (item > items) {
        item = item - items;
      }
  
      item = Math.ceil(item / slideBy);
      items = Math.ceil(items / slideBy);
  
      $(".owl-dots").html(padDigits(item, 2) + " / " + padDigits(items, 2));
    };
  
  
  const counter_modal = (event) => {
    var items = event.item.count;
    var slideBy = event.relatedTarget.settings.slideBy;

    var indexCount = 1;
    if (slideBy == 1) {
      indexCount = 1;
    }

    if (loop == true) {
      var item = event.item.index - 2;
    } else {
      var item = event.item.index + indexCount;
    }

    // it loop is true then reset counter from 1
    if (item > items) {
      item = item - items;
    }

    item = Math.ceil(item / slideBy);
    items = Math.ceil(items / slideBy);

    $(".modal-wrapper .owl-dots").html(item + " / " + items);
  };

  // const filterDdata= data.filter(data=>{
  //     if(data.tags.map)
  // })
  // const blogImagePath = slug.replace(CONSTANTS.BLOG_PATH, '');
  
  return (<>{props?.filteredData && props?.filteredData?.map(function (filteredData, index) {
    return (
      <>
      <a className="card_hover" href="#modal"> 
      <div className={CardStyles.card} key={index}>
          <div className={CardStyles.cardImageWrapper}>
            <img src={filteredData.headerImg}></img>
          </div>
          <div className={CardStyles.cardcontent}>
            {" "}
            <div className={CardStyles.solution_Area}>
              {filteredData.solutionArea}
            </div>
            <div className={CardStyles.cardTitle}>
                <p>{filteredData.projectName}</p>
              
            </div>
            <div className={CardStyles.cardCategoryTime}>
             <img src={filteredData.img}></img>  
            </div>
          </div>
        </div>
        {/* <div className={CardStyles.card_container}>
          <div className={CardStyles.card_headerimg}><img src={filteredData.headerImg}></img></div>
          <div className={CardStyles.footercontent}>
            <div className={CardStyles.card_solutionarea}>{filteredData.solutionArea}</div>
            <div className={CardStyles.card_title}>{filteredData.projectName}</div>
            <div className={CardStyles.footerimg}><img src={filteredData.img}></img></div>
          </div>

        </div> */}
        </a>
       
        <div id="modal" className={`${styles["modaloverlay"]} modal-wrapper`}>
          <div className={`${styles["modaloverlay__modal"]}`}>
            <a href="#close" className={`${styles["modaloverlay__close"]}`}>
              &times;
            </a>
            <div className={`${styles["modaloverlay__body"]}`}>
              <img
                className={`${styles["modaloverlay__img"]}`}
                src="../../solutions/solutions/modal/straumann-logo.png"
                alt="Go to top"
              />
              <h6 className={`${styles["modaloverlay__heading"]}`}>
                PROJECT INFO
              </h6>
              <p className={`${styles["modaloverlay__content"]}`}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Accumsan id consequat tellus ac a, volutpat. Commodo fermentum
                facilisis odio posuere augue senectus diam. Dolor vestibulum
                lobortis accumsan dignissim a quisque sed. Enim, posuere ut eget
                nisl.
              </p>
              {typeof window !== "undefined" && OwlCarousel && (
                <OwlCarousel
                  className={`${styles["owl_modal_theme"]} ${styles["modal-carousel"]}`}
                  // loop="false"
                  margin={20}
                  autoplay="false"
                  autoplayTimeout="6000"
                  autoplaySpeed="1000"
                  autoplayHoverPause="true"
                  nav="true"
                  // navText="['', '']"
                  dots="true"
                  lazyLoad="true"
                  responsive={modalCarouselResponsive}
                  onInitialized={counter_modal}
                  onTranslated={counter_modal}
                >
                  <div className={`${styles["owl_modal_theme__item"]}`}>
                    <img
                      className={`${styles["owl_modal_theme__image"]}`}
                      src="../../../../solutions/solutions/modal/straumann.png"
                      alt="HASHOUT"
                    />
                  </div>
                  <div className={`${styles["owl_modal_theme__item"]}`}>
                    <img
                      className={`${styles["owl_modal_theme__image"]}`}
                      src="../../../../solutions/solutions/modal/straumann.png"
                      alt="HASHOUT"
                    />
                  </div>
                  <div className={`${styles["owl_modal_theme__item"]}`}>
                    <img
                      className={`${styles["owl_modal_theme__image"]}`}
                      src="../../../../solutions/solutions/modal/straumann.png"
                      alt="HASHOUT"
                    />
                  </div>
                  <div className={`${styles["owl_modal_theme__item"]}`}>
                    <img
                      className={`${styles["owl_modal_theme__image"]}`}
                      src="../../../../solutions/solutions/modal/straumann.png"
                      alt="HASHOUT"
                    />
                  </div>
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </>
    );
  })}</>
    );
}

export default Ourworkcard;
